<template>
    <div>
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange"
            @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup" class="h-handle-button">
                <div class="h-b">
                    <el-button type="primary" size="small" v-if="!isDisabled" @click="addConract"
                        v-right-code="'/projectinfo/add'">添加</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" v-if="!isDisabled" @click="remove"
                        v-right-code="'/projectinfo/save'">移除</el-button>
                </div>
            </div>
            <p slot="elList">
                <el-table ref="pcListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange"
                    highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col, index, count)  in dataSource.ColDefs.BodyFieldParams" :key="index"
                        :prop="col.FieldName" :label="col.DisplayName" :render-header="bindFilter(queryParam, col)"
                        :fixed="index == 0 && (!config || !config.isDetailDisplay)"
                        :min-width="(!config || !config.isDetailDisplay) ? parseInt(col.Width) : 0"
                        v-if="col.Visible && (!config || !config.isDetailDisplay || (config.isDetailDisplay && index < 2))">
                        <template slot-scope="scope">
                            <span v-if="col.FieldName === 'Code'"><span type="text"
                                    style="text-decoration:underline;color:#1874CD;">{{
                                        scope.row[col.FieldName] }}</span></span>
                            <span v-else> {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </fixed-list>
        <DialogEx :options="contractOptions" title="合同选择" style="height:100%;" @onOk="onContractOk">
            <Contract ref="contract" :config="dialogConfig" @onSelectedRow="onContractSelectedRow">
            </Contract>
        </DialogEx>
    </div>
</template>

<script>
export default {
    mounted() { },
    data() {
        return {
            multipleSelection: [],
            queryParam: {
                PageIndex: 1,
                PageSize: 10,
                PageParams: {},
                params: {}
            },
            dataSource: {
                ColDefs: {},
                Result: [],
                TotalCount: 0
            },
            dialogConfig: {
                isDetailDisplay: false,
                isSelectionDisplay: true,
            },
            config: {
                isDetailDisplay: false,
                isSelectionDisplay: true,
            },
            contractOptions: {
                visible: false,
                size: 'large'
            },
            contractSelecteData: [],
        }
    },
    props: {
        projectData: {},
        isDisabled: false
    },
    methods: {
        getContractList: function (Id) {
            if (typeof Id != 'undefined' && Id != '' && Id != null) {
                this.queryParam.params.ProjectId = Id;
            }
            this.onPageChange();
        },
        reloadPageList: function () {
            this.initialize();
        },
        initialize() {
            this.onPageChange(this.queryParam);
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },

        onPageChange(param) {
            var _this = this;
            var param = this.Utils.cloneObj(this.queryParam);
            var projectId = param.params.ProjectId || this.projectData.Id;
            param.PageParams = {
                ProjectId: projectId
            };
            this.$ajax.query("omsapi/projectinfo/getpcpagelist", "post", param, data => {
                _this.dataSource = data;
                this.projectData.CrList = [];
            });
        },
        onDataSourceChange(ds) {
            var _this = this;
            _this.dataSource = {
                ColDefs: {
                    BodyFieldParams: []
                },
                Result: [],
                TotalCount: 0
            };
            _this.$nextTick(function () {
                _this.dataSource = ds;

            });
        },
        addConract() {
            this.contractSelecteData = [];
            this.contractOptions.visible = true;
        },
        onContractSelectedRow(rows) {
            this.contractSelecteData = rows;
        },
        onContractOk() {
            if (this.contractSelecteData.length <= 0)
                return;
            this.dataSource.Result = this.dataSource.Result || [];
            this.projectData.CrList = this.projectData.CrList || [];
            this.contractSelecteData.forEach(item => {
                if (this.dataSource.Result.filter(m => m.ContractId == item.Id).length <= 0) {
                    var newRow = {
                        "Id": null,
                        "ContractId": item.Id,
                        "Code": item.Code,
                        "Name": item.Name,
                        "TypeName": item.TypeName,
                        "DisplayStatus": item.DisplayStatus,
                        "PaymentTypeName": item.PaymentTypeName,
                        "CustomerName": item.CustomerName,
                        "Status": item.Status,
                    };
                    this.dataSource.Result.push(newRow);
                    this.projectData.CrList.push(newRow);
                }
            });
        },
        remove() {
            var _this = this;
            if (this.multipleSelection.length <= 0) {
                return this.Utils.messageBox("请选择移除项目数据.", "warning");
            }
            this.$confirm('是否移除选择数据?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var removeList = this.multipleSelection.filter(m => m.Id == null);
                var delList = this.multipleSelection.filter(m => m.Id != null);
                if (_this.dataSource.Id !== null && delList.length > 0) {
                    _this.$ajax.send("omsapi/projectinfo/removecontracts", "post", delList, (data) => {
                        if (data.IsSuccess) {
                            _this.Utils.messageBox("移除成功.", "success");
                            delList.forEach(item => {
                                this.dataSource.Result.remove(item);
                                if (this.projectData.CrList.length > 0)
                                    this.projectData.CrList.remove(item);
                            });
                        } else {
                            _this.Utils.messageBox("移除失败，清重试.", "warning");
                        }
                    });
                } else {
                    removeList.forEach(item => {
                        this.dataSource.Result.remove(item);
                        if (this.projectData.CrList.length > 0)
                            this.projectData.CrList.remove(item);
                    });

                }
            });

        }
    },
    components: {
        "Contract": resolve => {
            require(['@/components/business/selector/contractselector.vue'], resolve)
        },
    }
}
</script>

<style></style>
